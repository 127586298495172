.listProject {
  padding: 10px 12px;
}
.listProject .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.listProject .header div:first-of-type {
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}
.listProject .header div:last-of-type {
  font-size: 12px;
  color: #999999;
}
.listProject .box.img {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px 8px;
  height: 114px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.listProject .box.img img {
  min-width: 160px;
  width: 160px;
  min-height: 90px;
  height: 90px;
  margin-right: 8px;
}
.listProject .box.img .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}
.listProject .box.img .content > p {
  font-size: 14px;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.listProject .box.img .content .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.listProject .box.img .content .text .el-tag {
  margin-bottom: 3px;
}
.listProject .box.img .content .text p {
  font-size: 12px;
  color: #999999;
}
.listProject .box.txt {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px 8px;
  min-height: 100px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.listProject .box.txt p {
  font-size: 12px;
  color: #666666;
}
.listProject .box.txt .first h4 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
}
.listProject .box.txt .second {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
