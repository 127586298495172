.myupload1 /deep/ .el-upload {
  width: 94px;
  height: 94px;
  border: none;
}
.mylabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
