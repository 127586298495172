.richText {
  width: 100%;
  word-wrap: break-word;
  padding: 10px 12px;
}
.richText .nodata {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
