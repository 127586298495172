



















































































































































































.flexca p {
  font-size: 14px;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 1.6em;
}

.operationBlock {
  position: absolute;
  right: -34px;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
}
