




















































.boxWrap {
  overflow: hidden;

  .box {
    width: 25%;
    float: left;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0;
    flex-direction: column;
    position: relative;

    img {
      width: 38px;
      height: 38px;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      color: #333333;
    }
  }
}
