.myupload2 /deep/ .el-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: 0 auto;
  max-width: none;
  height: 115px;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px dashed #43A1FF;
  background: #fff;
  color: #43A1FF;
}
.mylabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
