

































































































































































































































































.dialogsty {
  width: 90px;
  height: 90px;
  border: 1px solid #F5F5F5;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;

  &.active {
    background: #F5F5F5;
  }
}
.mylabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.boderR /deep/ .el-input__inner{
  border:1px solid red
}
