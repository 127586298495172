.vdr {
  border: none;
}
.vdr.active {
  color: #2c3e50;
}
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
  user-select: none;
}
.framePage-halfBody .framePage-body {
  position: relative;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 400px 0 150px;
}
.framePage-halfBody .framePage-body .tagList {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.framePage-halfBody .framePage-body .tagList .tag {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #F8F8F8;
  position: relative;
}
.framePage-halfBody .framePage-body .tagList .tag img {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}
.framePage-halfBody .framePage-body .tagList .tag p {
  font-size: 14px;
  color: #333333;
}
.framePage-halfBody .framePage-body .tagList .tag .detail {
  position: absolute;
  right: -115px;
  top: 10px;
}
.framePage-halfBody .framePage-body .tagList .tag .detail .icon {
  position: absolute;
  font-size: 18px;
  top: 38px;
  left: -12px;
  color: #fff;
}
.framePage-halfBody .framePage-body .tagList .tag .detail .tag {
  width: 100px;
  height: 100px;
  background: #ffffff;
  cursor: pointer;
}
.framePage-halfBody .framePage-body .tagList .tag .detail .tag img {
  filter: grayscale(100%);
}
.framePage-halfBody .framePage-body .tagList .tag .detail .tag.active img {
  filter: none;
}
.framePage-halfBody .framePage-body .tagList .tag.active {
  background: #fff;
}
.framePage-halfBody .framePage-body .tagList .tag.active img {
  filter: none;
}
.framePage-halfBody .framePage-body .phone {
  position: relative;
  width: 375px;
  min-height: 650px;
  background: #F5F5F5;
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
  padding-bottom: 49px;
}
.framePage-halfBody .framePage-body .phone > div:not(.phone_bot) {
  position: relative;
  box-sizing: border-box;
  border: 2px dashed transparent;
}
.framePage-halfBody .framePage-body .phone > div:not(.phone_bot):hover,
.framePage-halfBody .framePage-body .phone > div:not(.phone_bot).activ {
  border: 2px dashed #2878FF;
}
.framePage-halfBody .framePage-body .phone > div:not(.phone_bot) .operationBlock {
  position: absolute;
  top: 0;
  right: -76px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.framePage-halfBody .framePage-body .phone > div:not(.phone_bot) .operationBlock img {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
  cursor: pointer;
}
.framePage-halfBody .framePage-body .phone > div:not(.phone_bot) .operationBlock img:last-of-type {
  margin-bottom: 0;
}
.framePage-halfBody .framePage-body .phone .phone_bot {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
}
.framePage-halfBody .framePage-body .phone .phone_bot .box {
  width: 25%;
  float: left;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: #fff;
}
.framePage-halfBody .framePage-body .phone .phone_bot .box img {
  width: 22px;
  height: 22px;
}
.framePage-halfBody .framePage-body .phone .phone_bot .box p {
  font-size: 14px;
  color: #666666;
}
.framePage-halfBody .framePage-body .phone .phone_bot .box:first-of-type p {
  color: #2878FF;
}
 /deep/ .el-form-item__label {
  width: 100%;
}
.pageContol {
  position: relative;
}
.pageContol .right {
  position: absolute;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  background: #fff;
  z-index: 2;
  padding: 30px 40px 60px 20px;
  overflow-y: auto;
}
.pageContol .right /deep/ .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.pageContol .right /deep/ .header img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}
.pageContol .right /deep/ .header p {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}
.pageContol .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding-left: 150px;
  background: #fff;
  width: calc(100% - 400px);
  box-shadow: 0px -3px 12px 1px rgba(0, 0, 0, 0.08);
  z-index: 33;
}
